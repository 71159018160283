import {
    Button,
    Grid,
    Input,
    MenuItem,
    Select,
    InputLabel,
    Tooltip,
    DialogContentText,
    TextField,
} from '@material-ui/core'
import React, { useState, useMemo } from 'react'
import axios from '../../axios'
import { handleErrors } from '../../utils'
import styled from 'styled-components'
import BasicDialog from '../form/BasicDialog'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core'
import TableSortLabel from '@material-ui/core/TableSortLabel'

const GroupHeader = styled.h3`
    margin-bottom: 5px;
    margin-top: 30px;
    text-decoration: underline;
    margin-left: -10px;
`

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`
const listItems = (lists) =>
    lists
        .filter((x) => x.items.length)
        .map(({ key, items }) => (
            <div>
                <h4 style={{ marginBottom: '5px', marginTop: '5px' }}>{key}</h4>
                <ul style={{ marginTop: '5px' }}>
                    {items.map(({ title, handle, quantity }) => (
                        <li>
                            <a
                                href={`https://www.ritani.com/products/${handle}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {title}
                            </a>
                            ({quantity})
                        </li>
                    ))}
                </ul>
            </div>
        ))

const Customers = () => {
    const [colId, setColId] = useState('')
    const [colType, setColType] = useState('email')
    const [loading, setLoading] = useState(false)
    const [customerInfo, setCustomerInfo] = useState()
    const [points, setPoints] = useState()
    const [confirmPoints, setConfirmPoints] = useState()
    const [pointsNote, setPointsNote] = useState('')
    const [pointsError, setPointsError] = useState('')
    const [open, setOpen] = useState(false)

    const search = async () => {
        if (!colId || !colType) return

        try {
            setLoading(true)
            const { data } = await axios.get(`/customers/${colType}/${colId}`)
            setCustomerInfo(data)
        } catch (err) {
            handleErrors({ err })
        } finally {
            setLoading(false)
        }
    }

    const applyPoints = async () => {
        if (!points || !pointsNote) setPointsError('Please fill in both fields')
        else setPointsError('')
        try {
            await axios.post(`/customers/apply-points`, {
                customerEmail: customerInfo?.customer?.email,
                points: points,
                note: pointsNote,
            })
            setPoints(0)
            setPointsNote('')
            setOpen(false)
            search()
        } catch (err) {
            console.log(err)
            handleErrors({ err })
        }
    }

    const { customer, cart, builder, wishlist, pointsHistory } =
        customerInfo || {}

    const [sortConfig, setSortConfig] = useState({
        key: 'created_at',
        direction: 'desc',
    })
    console.log(customer)

    const sortedPointsHistory = useMemo(() => {
        if (!pointsHistory) return []
        const sorted = [...pointsHistory]
        if (sortConfig.key) {
            sorted.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1
                }
                return 0
            })
        }
        return sorted
    }, [pointsHistory, sortConfig])

    const requestSort = (key) => {
        let direction = 'asc'
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc'
        }
        setSortConfig({ key, direction })
    }

    return (
        <div>
            <InputLabel style={{ left: '20px' }} id="col-type-label">
                Type
            </InputLabel>
            <Select
                labelId="col-type-label"
                id="col-type-select"
                label="Type"
                value={colType}
                style={{ width: '150px', marginRight: '20px' }}
                onChange={(e) => setColType(e.target.value)}
            >
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="cart">Cart</MenuItem>
                <MenuItem value="builder">Builder</MenuItem>
                <MenuItem value="wishlist">Wishlist</MenuItem>
            </Select>
            <Input
                style={{ width: '200px' }}
                placeholder={colType === 'email' ? 'Email' : `${colType} ID`}
                onChange={(e) => setColId(e.target.value)}
            />
            <Button color="primary" onClick={search}>
                Search By {colType === 'email' ? 'Email' : `${colType} ID`}
            </Button>
            <br />
            {customerInfo && (
                <Grid container spacing={6}>
                    {customer && (
                        <Grid item md={6}>
                            <GroupHeader>Customer</GroupHeader>
                            <div>
                                <b>Name:</b> {customer.name}
                            </div>
                            <div>
                                <b>Email:</b> {customer.email}
                            </div>
                            <div>
                                <b>Points:</b>{' '}
                                {customer.vs_retailer
                                    ? 'VS Retailers do not accumulate points'
                                    : customer.points || 0}
                            </div>
                        </Grid>
                    )}
                    {cart && (
                        <Grid item md={6}>
                            <GroupHeader>Carts</GroupHeader>
                            {listItems(cart)}
                        </Grid>
                    )}
                    {builder && (
                        <Grid item md={6}>
                            <GroupHeader>Builders</GroupHeader>
                            {listItems(builder)}
                        </Grid>
                    )}
                    {wishlist && (
                        <Grid item md={6}>
                            <GroupHeader>Wishlists</GroupHeader>
                            {listItems(wishlist)}
                        </Grid>
                    )}
                    {pointsHistory &&
                        pointsHistory.length &&
                        !customer.vs_retailer && (
                            <Grid item md={8}>
                                <GroupHeader>Points History</GroupHeader>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Identifier
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={
                                                            sortConfig.key ===
                                                            'points'
                                                        }
                                                        direction={
                                                            sortConfig.direction
                                                        }
                                                        onClick={() =>
                                                            requestSort(
                                                                'points'
                                                            )
                                                        }
                                                    >
                                                        Points
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={
                                                            sortConfig.key ===
                                                            'status'
                                                        }
                                                        direction={
                                                            sortConfig.direction
                                                        }
                                                        onClick={() =>
                                                            requestSort(
                                                                'status'
                                                            )
                                                        }
                                                    >
                                                        Status
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={
                                                            sortConfig.key ===
                                                            'created_at'
                                                        }
                                                        direction={
                                                            sortConfig.direction
                                                        }
                                                        onClick={() =>
                                                            requestSort(
                                                                'created_at'
                                                            )
                                                        }
                                                    >
                                                        Created At
                                                    </TableSortLabel>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedPointsHistory.map(
                                                (point) => (
                                                    <TableRow key={point.id}>
                                                        <TableCell>
                                                            {point.identifier}
                                                        </TableCell>
                                                        <TableCell>
                                                            {point.points}
                                                        </TableCell>
                                                        <TableCell>
                                                            {point.status}
                                                        </TableCell>
                                                        <TableCell>
                                                            {point.type}
                                                        </TableCell>
                                                        <TableCell>
                                                            {new Date(
                                                                point.created_at
                                                            ).toLocaleDateString()}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}
                    {'points' in customer && !customer.vs_retailer && (
                        <Grid item md={4}>
                            <GroupHeader>Add Points</GroupHeader>
                            <FormContainer>
                                <TextField
                                    style={{ maxWidth: '150px' }}
                                    label="Amount"
                                    value={points}
                                    onChange={(e) =>
                                        setPoints(
                                            e.target.value < 0
                                                ? ''
                                                : e.target.value > 5000
                                                ? 5000
                                                : e.target.value
                                        )
                                    }
                                    type="number"
                                />
                                <TextField
                                    label="Note"
                                    value={pointsNote}
                                    onChange={(e) =>
                                        setPointsNote(e.target.value)
                                    }
                                />
                                <Tooltip
                                    title={
                                        !points || !pointsNote
                                            ? 'Please fill in both fields'
                                            : ''
                                    }
                                    placement="bottom-start"
                                >
                                    <span>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => setOpen(true)}
                                            disabled={!points || !pointsNote}
                                        >
                                            Apply
                                        </Button>
                                    </span>
                                </Tooltip>
                            </FormContainer>
                        </Grid>
                    )}
                </Grid>
            )}

            <BasicDialog
                open={open}
                onClose={() => setOpen(false)}
                title={
                    <span>
                        Confirm adding <b>{points}</b> points to:{' '}
                        <b>{customer?.email}</b>
                    </span>
                }
                actions={
                    <>
                        <Button
                            onClick={() => setOpen(false)}
                            color="secondary"
                            autoFocus
                        >
                            Cancel
                        </Button>

                        <Tooltip
                            title={
                                points !== confirmPoints
                                    ? 'Please confirm the amount'
                                    : ''
                            }
                        >
                            <span>
                                <Button
                                    color="primary"
                                    onClick={applyPoints}
                                    disabled={points !== confirmPoints}
                                >
                                    Apply
                                </Button>
                            </span>
                        </Tooltip>
                    </>
                }
            >
                <TextField
                    label="Amount"
                    value={confirmPoints}
                    onChange={(e) =>
                        setConfirmPoints(
                            e.target.value < 0
                                ? ''
                                : e.target.value > 5000
                                ? 5000
                                : e.target.value
                        )
                    }
                    type="number"
                />
            </BasicDialog>
            {pointsError && (
                <DialogContentText id="alert-dialog-description">
                    {pointsError}
                </DialogContentText>
            )}
        </div>
    )
}

export default Customers
